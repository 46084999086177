import React, {useEffect, useState} from "react";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {Buffer} from 'buffer';
import {CSVLink} from "react-csv";
import {saveAs} from 'file-saver';

export const Root = () => {

    type Group = {
        name: string,
        campaignId: string,
        recipients: string,
        campaignType: string,
        campaignFriendlyName: string,
    }

    type Nps = {
        npsid: string,
        creatorFirstName: string,
        email: string,
        url: string,
    }

    type GroupNps = {
        nps: Nps[],
    }

    const [group, setGroup] = useState({
        name: "",
        campaignId: "",
        recipients: "",
        campaignType: "Standard Campaign",
        campaignFriendlyName: ""
    } as Group);

    const [searchGroup, setSearchGroup] = useState("");
    const [groupNps, setGroupNps] = useState({nps: []} as GroupNps);
    const NavigateTo = useNavigate();
    const search = useLocation().search;
    const links = new URLSearchParams(search).get("links");
    const [isEmptyNameError, setIsEmptyNameError] = useState(false);
    const [isEmptyCampaignIdError, setIsEmptyCampaignIdError] = useState(false);
    const [isEmptyCampaignFriendlyNameError, setEmptyCampaignFriendlyNameError] = useState(false);
    const [isEmptySearchGroupError, setEmptySearchGroupError] = useState(false);
    const [isDuplicateFriendlyNameError, setDuplicateFriendlyNameError] = useState(false);
    const [isTextAreaValid, setTextAreaInvalid] = useState(false);

    const data = React.useMemo(() => {
        return groupNps.nps.map((d) => Object.values(d));
    }, [groupNps]);

    const renderEmptySearchCampaignIdError = () => {
        if (isEmptySearchGroupError) {
            return (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mx-4 rounded relative text-center"
                    role="alert">
                    <span className="block sm:inline"> Please enter a campaign ID to search for</span>
                </div>
            )
        }
    }

    const renderTextAreaInValidError = () => {
        return isTextAreaValid ? (
            <div className="px-4 error">
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mx-4 rounded relative text-center"
                    role="alert">
                    <span className="block sm:inline">Input not in the correct format</span>
                </div>
            </div>) : null;
    }

    const renderEmptyCampaignIdError = () => {
        return isEmptyCampaignIdError ? (
            <div className="px-4 error">
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mx-4 rounded relative text-center"
                    role="alert">
                    <span className="block sm:inline">Please add the Campaign Name</span>
                </div>
            </div>) : null;
    }

    const renderEmptyNameError = () => {
        return isEmptyNameError ? (
            <div className="px-4 error">
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mx-4 rounded relative text-center"
                    role="alert">
                    <span className="block sm:inline">Please add the Name for this collection</span>
                </div>
            </div>) : null;
    }

    const renderEmptyFriendlyNameError = () => {
        return isEmptyCampaignFriendlyNameError ? (
            <div className="px-4 error">
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mx-4 rounded relative text-center"
                    role="alert">
                    <span className="block sm:inline">Please add the Friendly Name for this collection</span>
                </div>
            </div>) : null;
    }

    const renderDuplicateFriendlyNameError = () => {
        return isDuplicateFriendlyNameError ? (
            <div className="px-4 error">
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mx-4 rounded relative text-center"
                    role="alert">
                    <span className="block sm:inline">This name has already been taken, please enter a unique name</span>
                </div>
            </div>) : null;
    }

    const renderCampaignId = () => {
        return group.campaignType !== "Bread" && group.campaignType !== "Yogscast (Campaigns)" ? (
            <div>
                <input name="campaignId" required type="text" value={group.campaignId} onChange={updateCampaignId}
                       placeholder="Campaign ID"
                       className="text-center shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
        ) : null;
    }

    useEffect(() => {
        if (links) {
            const buf = Buffer.from(links, 'base64');
            const data = buf.toString('utf-8');
            console.log(data);
            setGroupNps(JSON.parse(data));
        }
    }, [links]);

    const submitGroup = (e: any) => {
        e.preventDefault();
        if (group.recipients === "") {
            setTextAreaInvalid(true)
        } else if (group.name === "") {
            setIsEmptyNameError(true)
        } else if (group.campaignId === "" && group.campaignType !== "Bread" && group.campaignType !== "Yogscast (Campaigns)") {
            setIsEmptyCampaignIdError(true)
        } else if (group.campaignFriendlyName === "") {
            setEmptyCampaignFriendlyNameError(true)
        } else if (group.name !== "" || (group.campaignId === "" && group.campaignType !== "Bread" && group.campaignType !== "Yogscast (Campaigns)")) {
            console.log("POSTING")

            const encodedString = Buffer.from(group.recipients).toString('base64');
            console.log(encodedString)

            axios.post("https://nps-api.fourthfloorcreative.co/nps-service/v1/links", {
                name: group.name,
                campaignId: group.campaignId,
                campaignFriendlyName: group.campaignFriendlyName,
                npsRawData: encodedString,
                campaignType: group.campaignType,
            }).then(res => {
                const buf = Buffer.from(JSON.stringify(res.data), 'utf-8')
                NavigateTo("/?links=" + buf.toString('base64'));
            }).catch(err => {
                console.log(err.response.status)
                if (err.response.status === 409) {
                    setDuplicateFriendlyNameError(true)
                }
            });
        } else {
            console.log("Cannot Post")
        }
    }

    const getResults = (e: any) => {
        e.preventDefault();
        if (searchGroup === "") {
            setEmptySearchGroupError(true)
        } else {
            console.log("Get Results back from campaign name: " + searchGroup)

            axios.get("https://nps-api.fourthfloorcreative.co/nps-service/v1/export/" + searchGroup).then(res => {
                let blob = new Blob([res.data]);
                saveAs(blob, searchGroup + '_nps.csv');
            }).catch(err => {
                console.log(err);
            });
        }
    }

    const reloadPage = () => {
        groupNps.nps = [];
        NavigateTo("/");
        window.location.reload();
    }

    const updateSearchGroup = (e: any) => {
        setSearchGroup(e.target.value);
    }

    const updateGroupName = (e: any) => {
        setGroup({
            name: e.target.value,
            campaignId: group.campaignId,
            recipients: group.recipients,
            campaignType: group.campaignType,
            campaignFriendlyName: group.campaignFriendlyName
        });
    }

    const updateCampaignId = (e: any) => {
        setGroup({
            name: group.name,
            campaignId: e.target.value,
            recipients: group.recipients,
            campaignType: group.campaignType,
            campaignFriendlyName: group.campaignFriendlyName
        });
    }

    const updateCampaignType = (e: any) => {
        setGroup({
            name: group.name,
            campaignId: group.campaignId,
            recipients: group.recipients,
            campaignType: e.target.value,
            campaignFriendlyName: group.campaignFriendlyName
        });
        console.log(e.target.value)
    };

    const updateEmailTextArea = (e: any) => {

        let worked = false;
        let lines = e.target.value.split('\n');
        lines.forEach((line: string) => {
            if (line.length > 0) {
                const lineSplit = line.split(',');
                if (lineSplit.length !== 2) {
                    worked = true;
                }
            }
        })

        setTextAreaInvalid(worked);

        setGroup({
            name: group.name,
            campaignId: group.campaignId,
            recipients: e.target.value,
            campaignType: group.campaignType,
            campaignFriendlyName: group.campaignFriendlyName
        });
    }

    const updateCampaignFriendlyName = (e: any) => {
        setGroup({
            name: group.name,
            campaignId: group.campaignId,
            recipients: group.recipients,
            campaignType: group.campaignType,
            campaignFriendlyName: e.target.value
        });
    }

    if (groupNps.nps.length > 0) {
        return (
            <div className="w-full flex p-20 justify-center bg-white h-screen overflow-y-scroll">
                <div
                    className="w-full max-w-6xl bg-secondary-grey2 shadow-md rounded-md center space-y-5 overflow-y-scroll">


                    <button name="submitBtn" onClick={reloadPage}
                            className="text-white p-5 bg-primary-orange hover:bg-secondary-orange3 focus:ring-4 focus:outline-none focus:animate-pulse font-medium rounded-lg text-sm py-2.5 ml-4 mt-4 mb-0 text-center">
                        <span className="text-white font-bold">&lt; Go back</span>
                    </button>


                    <h3 className="text-center text-white text-2xl font-bold py-0">Generated NPS Links</h3>


                    <div className="w-full flex justify-center">
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-white text-gray-200">
                                <thead
                                    className="text-xs uppercase dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Creator
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Email
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Link
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                {groupNps.nps.map((nps, index) => {
                                        return (
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                    {nps.creatorFirstName}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {nps.email}
                                                </td>
                                                <td className="px-6 py-4 dark:text-white">
                                                    {nps.url}
                                                </td>
                                            </tr>
                                        )
                                    }
                                )}
                                </tbody>
                            </table>
                            <div
                                className="min-w-fit flex justify-center text-sm dark:bg-gray-700 text-white px-6 py-3">
                                <CSVLink data={data}>Click to download data as CSV</CSVLink>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className="w-full text-center flex justify-center p-10 bg-white h-screen overflow-y-scroll">
            <div
                className="w-full max-w-6xl bg-secondary-grey2 shadow-md rounded-md center space-y-5 overflow-y-scroll">
                <form className="flex flex-col justify-center items-center space-y-5 p-5">
                    <div className="text-white text-3xl font-bold underline"> NPS Toolbox</div>
                    <div
                        className="flex flex-col justify-center items-center space-y-5 border-secondary-grey3 p-5 w-full border-4">
                        <div className="text-white text-xl font-bold underline "> Generate Links</div>
                        <input name="npsGroupName" required type="text" value={group.name} onChange={updateGroupName}
                               placeholder="NPS Group Name"
                               className="text-center shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                        {renderEmptyNameError()}
                        <input name="npsCampaignFriendlyName" required type="text" value={group.campaignFriendlyName}
                               onChange={updateCampaignFriendlyName}
                               placeholder="Friendly Name"
                               className="text-center shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                        {renderEmptyFriendlyNameError()}
                        {renderDuplicateFriendlyNameError()}
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-200">Select a Campaign
                                Type:</label>

                            <select name="selectCampaignType" id="campaign-type"
                                    className="text-center shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={updateCampaignType}>
                                <option value="Standard Campaign">Standard Campaign</option>
                                <option value="Yogscast (Campaigns)">Yogscast (Campaigns)</option>
                                <option value="Bread">Bread</option>
                                <option value="Events and Production">Events and Production</option>
                                <option value="Advocacy">Advocacy</option>
                                <option value="Nordics">Nordics</option>
                            </select>
                        </div>
                        <div>
                            {renderCampaignId()}
                        </div>
                        <div>
                            {renderEmptyCampaignIdError()}
                        </div>

                        <div className="text-white text-xl font-bold"> Add emails</div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-200">Format: name,email, one per
                                line:</label>
                            <label className="mb-8 text-sm font-medium text-gray-200">Example:
                                spacecat,jeff@spacecatland.com</label>
                        </div>
                        <div className="mx-8 my-3 flex justify-center">
                            <textarea name="enterEmailArea" onChange={updateEmailTextArea}
                                      className="w-96 h-20 resize-none outline-0 border-8 border-white rounded"/>
                        </div>
                        <div>
                            {renderTextAreaInValidError()}
                        </div>

                        <button name="submitBtn" onClick={submitGroup}
                                className="text-white p-5 bg-primary-orange hover:bg-secondary-orange3 focus:ring-4 focus:outline-none focus:animate-pulse font-medium rounded-lg text-sm px-5 py-2.5 text-center">Submit
                            group
                        </button>
                    </div>
                    <div
                        className="flex flex-col justify-center items-center space-y-5 border-secondary-grey3 p-5 w-full border-4">
                        <div className="text-white text-xl font-bold underline"> View Results</div>
                        <input name="npsSearchGroupName" required type="text" value={searchGroup}
                               onChange={updateSearchGroup}
                               placeholder="NPS Group Name"
                               className="text-center shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "/>
                        <button name="submitSearchBtn" onClick={getResults}
                                className="text-white p-5 bg-primary-orange hover:bg-secondary-orange3 focus:ring-4 focus:outline-none focus:animate-pulse font-medium rounded-lg text-sm px-5 py-2.5 text-center">Download
                            Results
                        </button>
                        {renderEmptySearchCampaignIdError()}
                    </div>
                </form>
            </div>
        </div>
    )
}
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Input} from './pages/input/input';
import {Root} from './pages/root/root';

function App() {
  return (
      <Router>
          <Routes>
              <Route path="/" element={<Root/>} />
              <Route path="/:uuid" element={<Input/>} />
          </Routes>
      </Router>
  );
}


export default App;

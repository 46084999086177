// All route props (match, location and history) are available to User
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from '@mui/material/styles';
import {Rating} from "@material-ui/lab";
import FavoriteIcon from '@mui/icons-material/RadioButtonChecked';
import FavoriteBorderIcon from '@mui/icons-material/RadioButtonUnchecked';
import {Buffer} from "buffer";
import axios from "axios";

export const Input = () => {
    let {uuid} = useParams()

    type Campaign = {
        campaignName: string,

    }

    const [nps, setNps] = useState(0);
    const [npsShowScoreError, setNpsShowScoreError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [freetext, setFreetext] = useState("")
    const [campaign, setCampaign] = useState({} as Campaign)
    const [isValidCampaign, setIsValidCampaign] = useState<boolean>(true);

    const renderNoNPSScoreSet = () => {
        return npsShowScoreError ? (
            <div className="px-4 error">
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mx-40 mb-4 rounded relative text-center" role="alert">
                    <span className="block sm:inline">Please submit a score.</span>
                </div>
            </div>) : null;
    }

    React.useEffect(() => {
        axios.get(`https://nps-api.fourthfloorcreative.co/nps-service/v1/id?key=${uuid}`).then(res => {
            setCampaign(res.data)
        }).catch(() => {
            setIsValidCampaign(false)
        })
    }, [uuid])

    const submitNps = () => {
        if (nps === 0) {
            setNpsShowScoreError(true)
            return
        }

        // Post nps to backend
        const encodedString = Buffer.from(freetext).toString('base64');
        axios.post(`https://nps-api.fourthfloorcreative.co/nps-service/v1/score?id=${uuid}&score=${nps}&freetext=${encodedString}`, null).then(() => {
            setSubmitted(true);
        }).catch((e) => {
            console.log("Error submitting nps: ", e);
        });
    }

    const updateFreeText = (e: any) => {
        setFreetext(e.target.value);
    }

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#ffffff',
        },
        '& .MuiRating-iconHover': {
            color: '#ffffff',
        },
    });

    function HeaderMessage() {
        return isValidCampaign ? (
                <div
                    className="flex justify-center items-start p-4 rounded-t border-b border-secondary-grey3">
                    <h3 className="text-xl text-center text-white">
                        Regarding your experience with {campaign.campaignName}, how likely is it that you would recommend Fourth Floor to a friend or colleague?
                    </h3>
                </div>
            ) :
            (
                <div
                    className="flex justify-center items-start p-4 rounded-t border-b border-secondary-grey3">
                    <h3 className="text-xl text-center text-white">
                        How likely is it that you would recommend Fourth Floor to a friend or colleague?
                    </h3>
                </div>
            );
    }

    function Information() {
        return (
            <div className="justify-center pl-3 grid grid-flow-col pt-3 text-xs text-gray-400">
                <div className="w-6">1</div>
                <div className="w-6">2</div>
                <div className="w-6">3</div>
                <div className="w-6">4</div>
                <div className="w-6">5</div>
                <div className="w-6">6</div>
                <div className="w-6">7</div>
                <div className="w-6">8</div>
                <div className="w-6">9</div>
                <div className="w-6">10</div>
            </div>
        )
    }

    function RadioGroupRating() {
        return (
            <div className="border-b border-secondary-grey3">
                <div
                    className="p-6 justify-evenly uppercase text-sm flex text-gray-400 border-secondary-grey3 pt-1">
                    Not at all likely
                    <StyledRating
                        name="simple-controlled"
                        value={nps}
                        max={10}
                        icon={<FavoriteIcon fontSize="inherit"/>}
                        emptyIcon={<FavoriteBorderIcon fontSize="inherit"/>}
                        onChange={(event: any, newValue: any) => {
                            setNps(newValue || nps);
                            setNpsShowScoreError(false);
                        }}/>
                    Extremely likely
                </div>
                {renderNoNPSScoreSet()}
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="justify-center flex w-full">
                <div className="p-4 w-full max-w-2xl h-full md:h-auto">
                    <div className="rounded-lg shadow bg-secondary-grey2">
                        {submitted ? (<>
                                <div className="flex justify-center items-start p-4 rounded-t border-secondary-grey3">
                                    <h3 className="text-xl text-center text-white">
                                        Thank you for your feedback!
                                    </h3>
                                </div>
                            </>
                        ) : (<>
                            {HeaderMessage()}
                            {Information()}
                            {RadioGroupRating()}
                            <h5 className="flex ml-8 text-white py-4"> Please supply any additional feedback
                                below: </h5>
                            <div className="mx-8 flex justify-center py-0">
                                <textarea className="w-full h-20 resize-none outline-0 border-8 border-white rounded"
                                          onChange={updateFreeText}/>
                            </div>
                            <div className="flex pb-4 justify-center py-5">
                                <button data-modal-toggle="defaultModal" type="button" onClick={submitNps}
                                        className="text-white p-5 bg-primary-orange hover:bg-secondary-orange3 focus:ring-4 focus:outline-none focus:animate-pulse font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                    Submit
                                </button>
                            </div>
                        </>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}